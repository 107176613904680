import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { omitDeep } from 'src/utils/helpers'
import { LOGOUT } from 'src/utils/types'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getStore } from '../store'

const { GRAPHQL_URI, WS_URI } = process.env

function getToken() {
  const { auth } = getStore().getState()

  return auth && auth.token
}

const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error, _operation) => !!error && _operation !== 'mutation',
  },
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
})

/* Apollo setup */
const httpLink = new HttpLink({ uri: GRAPHQL_URI })

const wsClient = new SubscriptionClient(WS_URI, {
  reconnect: true,
  connectionParams: () => ({
    authorization: getToken() ? `${getToken()}` : null,
  }),
})
const wsLink = new WebSocketLink(wsClient)

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const mainLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)

    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (process.env.NODE_ENV !== 'production') {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path, extensions = {} }) =>
        console.log(
          `%c [GraphQL error]: Message: ${message}, Code: ${extensions.code}, Path: ${path && path[0]}`,
          'color: red',
        ),
      )
    }
  }
  if (graphQLErrors) {
    graphQLErrors.map(
      ({ extensions = {} }) =>
        extensions.code === 'UNAUTHENTICATED' && getStore().dispatch({ type: LOGOUT.REQUEST, payload: null }),
    )
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    if (networkError.result && networkError.result.code === 401) {
      getStore().dispatch({ type: LOGOUT.REQUEST, payload: null })
      // eslint-disable-next-line
      networkError.result.errors = null
    }
  }
})

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken()
  operation.setContext({
    headers: { authorization: token ? `${token}` : null },
  })

  return forward(operation)
})

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, '__typename')
  }

  return forward(operation)
})

// logger
const loggerLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`%c [GraphQL Logger] received result from ${operation.operationName}`, 'color: gray')
      console.log(result?.data || result)
    }

    return result
  })
})

const mergeFn = (existing = [], incoming, { args: { offset = 0 } }) => {
  const existingIds = existing.map((e) => e.__ref)
  const filteredIncoming = incoming.filter((i) => !existingIds.includes(i.__ref))
  return offset === 0 ? [...filteredIncoming, ...existing] : [...existing, ...filteredIncoming]
}

const client = new ApolloClient({
  link: ApolloLink.from([cleanTypenameLink, authLink, loggerLink, errorLink, retryLink, mainLink]),
  cache: new InMemoryCache({
    possibleTypes: {
      UploadMedia: ['Photo', 'Video'],
    },
    dataIdFromObject: (o) => o._id,
    // addTypename: 'false',
    typePolicies: {},
  }),
})

export default client
