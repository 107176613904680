import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCCeUt9cgOCDpG0BQyQjadU5c0B6uqY_7w',
  authDomain: 'pris-46d61.firebaseapp.com',
  projectId: 'pris-46d61',
  storageBucket: 'pris-46d61.appspot.com',
  messagingSenderId: '10700184890',
  appId: '1:10700184890:web:4b23f6a4189104f3608403',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: 'BHi1c6xVAo-9HPbITPITkWKBLKo0NN9_uTB_TiPfSHdxd_lImhC3rxW7deqwUWiNwH42lkN2QeMuCOi-Z5_8h10',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('Current token for client: ', currentToken)
        setTokenFound(currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.')
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
