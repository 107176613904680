import React from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { connect, useDispatch } from 'react-redux'
import { updateUser } from 'src/containers/Auth/actions'
import { CONVERSATION_UNREAD_COUNT, CURRENT_USER } from '../../graphql/queries'
import SiderMenu from './index'

const WrapperMsgCount = (props) => {
  const { newMsgCount } = props
  const dispatch = useDispatch()

  useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const userInfo = data?.currentUser
      dispatch(updateUser(userInfo))
    },
    onError: (err) => {
      console.error('Get current user info error', err)
    },
  })

  useSubscription(CONVERSATION_UNREAD_COUNT, {
    onSubscriptionData: (data) => {
      dispatch(updateUser({ newMsgCount: data }))
    },
  })

  return <SiderMenu {...props} newMsgCount={newMsgCount || 0} />
}

const mapStateToProps = (state) => {
  return {
    newMsgCount: state?.auth?.user?.newMsgCount || 0,
  }
}

export default connect(mapStateToProps)(WrapperMsgCount)
