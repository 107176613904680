import history from 'src/utils/history'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'

import { logger } from 'redux-logger'
import { persistReducer, persistStore, purgeStoredState } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import { routerMiddleware } from 'connected-react-router'

import createRootReducer from 'src/common/reducers'
import rootSaga from 'src/common/sagas'

/* ------------- Redux Configuration ------------- */
let store
const middlewares = []

/* ------------- Middleware ------------- */
const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)
middlewares.push(routerMiddleware(history))

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const resetMiddleware = () => (next) => (reducer, initialState, enhancer) => {
  const resetType = 'RESET'
  const resetData = 'state'

  const enhanceReducer = (state, action) => {
    if (action.type === resetType) {
      state = action[resetData]
    }
    return reducer(state, action)
  }

  return next(enhanceReducer, initialState, enhancer)
}

/* ------------- Redux Persist ------------- */
const config = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(config, createRootReducer(history))

const configureStore = () => {
  store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middlewares), resetMiddleware()))

  const persistor = persistStore(store, null, () => {
    store.getState() // if you want to get restoredState
  })

  sagaMiddleware.run(rootSaga)
  store.close = () => store.dispatch(END)
  store.purge = () => purgeStoredState(config)

  return { persistor, store }
}

const getStore = () => store

export { configureStore, getStore }
