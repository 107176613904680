import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import moment from 'moment'

import App from './containers/App'
import './style.css'
import { registerServiceWorker } from './utils/serviceWorker'

moment.locale('en')

ReactDOM.render(
  <ConfigProvider locale={enUS}>
    <App />
  </ConfigProvider>,
  document.getElementById('app'),
)

registerServiceWorker()
