export const S3_FOLDER = 'https://s3-ap-northeast-1.amazonaws.com/pris-media/'

export const GENDERS = ['male', 'female', 'unisex']

export const BRAND = {
  STYLE: {
    luxury: 'Luxury',
    luxuryWatches: 'Luxury watches',
    fashion: 'Fashion',
    fashionVi: 'Vietnam fashion',
    accessories: 'Accessoriez',
    localAccessories: 'Local accessories',
    cosmetic: 'Cosmetic (hide from explore)',
    jewelry: 'Jewelry',
    special: 'Special',
    localGirl: 'Local girl',
    localGirlOversea: 'It girls',
    localMenswear: 'Local menswear',
    localMenswearOversea: 'Local menswear oversea',
    localBrand: 'Local Brand',
    entertainment: 'Entertainment',
    streetStyle: 'Street style',
    perfume: 'Perfume (hide from explore)',
    partySexy: 'Party sexy',
    u40: 'U40 (hide from explore)',
    office: 'Office',
    vintage: 'Vintage',
    korea: 'Korea brand',
    japanBrand: 'Japan brand',
    hanoi: 'Hanoi',
    sneakers: 'Sneakers',
    specialCloth: 'Special Cloth (hide from explore)',
    fashionDesigner: 'Fashion Designer',
    localDesigner: 'Local Designer',
    taoBao: 'TAO BAO (hide from explore)',
    bikini: 'Bikini (hide from explore)',
    sleepwear: 'Sleepwear (hide from explore)',
    hotGirlSouthern: 'Hot girl miền Nam',
    hotGirlNorthern: 'Hot girl miền Bắc',
    vintageGlobal: 'Vintage global brand',
    china: 'Nội địa Trung',
    sportwear: 'Sportwear',
    localGirlyNotGood: 'Local nữ bánh bèo không đẹp',
    studentBrand: 'Brand học sinh',
    graduationThesis: 'Đồ án tốt nghiệp',
    excludeFromFeed: 'Exclude from feed',
  },
}

export const REASON_DENY_BANNED = [
  'Bài đăng thiếu chân thực, không rõ ràng, không định dạng được hình dáng sản phẩm.',
  'Bài đăng có chứa nội dung nhạy cảm.',
]

export const PRIS_USERNAME = 'pris'

export const TRANSACTION = Object.freeze({
  STATUS: Object.freeze({
    CREATED: 'created',
    PENDING: 'pending',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
  }),
})

export const FEED = Object.freeze({
  AVAILABILITY: Object.freeze({
    SALE: 'sale',
    NOT_SALE: 'notSale',
    WANTED: 'wanted',
    POST: 'post',
  }),
})
