import React from 'react'
import { Layout } from 'antd'
import { Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash'
import { renderRoutes } from 'src/utils/services/router'
import { fetchToken, onMessageListener } from 'src/utils/firebase'
import { restrictChatOnly } from 'src/utils/helper'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

import GlobalHeader from '../components/GlobalHeader'
import GlobalFooter from '../components/GlobalFooter'
import SiderMenu from '../components/SiderMenu/SideMenuHOC'
import { getMenuData } from '../common/menu'
import { logout } from '../containers/Auth/actions'

const { Content, Header, Footer } = Layout

class BasicLayout extends React.PureComponent {
  state = {
    collapsed: false,
  }

  componentDidMount() {
    fetchToken((fcmToken) => {
      const { auth } = this.props
      if (auth?.token) {
        // Register token to server
        this.props.client.mutate({
          mutation: gql`
            mutation registerDeviceToken($token: String!) {
              registerDeviceToken(token: $token)
            }
          `,
          variables: { token: fcmToken },
        })
      }

      onMessageListener()
        .then((payload) => {
          console.log('onMessageListener', payload)
        })
        .catch((err) => console.error(err))
    })
  }

  badgeNew = () => {
    const {
      data: {
        ageVerification = { totalDocs: 0 },
        salaryVerification = { totalDocs: 0 },
        aboutVerification = { totalDocs: 0 },
        todayWordVerification = { totalDocs: 0 },
        imageVerification = { totalDocs: 0 },
        adminGetFeedbackList = { totalDocs: 0 },
      },
    } = this.props
    return [
      {
        name: 'Verifications',
        badge: salaryVerification.totalDocs + ageVerification.totalDocs,
      },
      {
        name: 'Image',
        badge: imageVerification.totalDocs,
      },
      {
        name: 'About',
        badge: aboutVerification.totalDocs,
      },
      {
        name: 'Tweet',
        badge: todayWordVerification.totalDocs,
      },
      {
        name: 'Feedback',
        badge: adminGetFeedbackList.totalDocs,
      },
    ]
  }

  handleMenuCollapse = (collapsed) => {
    this.setState({ collapsed })
  }

  render() {
    const { auth, location, history, loading } = this.props
    const { routes } = this.props.route

    return (
      <Layout>
        {!restrictChatOnly && <SiderMenu
          // theme="dark"
          collapsed={this.state.collapsed}
          location={location}
          menuData={getMenuData()}
          // badgeData={this.badgeNew()}
          onCollapse={this.handleMenuCollapse}
          history={history}
          loading={loading}
        />}
        <Layout>
          {!restrictChatOnly && <Header style={{ padding: 0, background: '#FFF' }}>
            <GlobalHeader
              currentUser={auth}
              collapsed={this.state.collapsed}
              onCollapse={this.handleMenuCollapse}
              logout={this.props.signOut}
              history={history}
            />
          </Header>}
          <Content style={{ margin: '24px 24px 0', height: '100%' }}>
            <Switch>{renderRoutes(routes, { hasNew: false }, auth?.admin?.role?.policy)}</Switch>
          </Content>
          {!restrictChatOnly && <Footer style={{ padding: 0 }}>
            <GlobalFooter />
          </Footer>}
        </Layout>
      </Layout>
    )
  }
}

export default compose(connect(({ auth }) => ({ auth, loading: auth.loading }), { signOut: logout }))(withApollo(BasicLayout))
