import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 64px;
  border-bottom: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
`
export const Logo = styled.img`
  width: 50px;
  height: 50px;
`
