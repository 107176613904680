import { isUrl } from 'src/utils/url'

const menuData = [
  {
    name: 'Statistics',
    path: 'statistics',
    icon: 'bar-chart',
  },
  {
    name: 'Explore Settings',
    path: 'explore-settings',
    icon: 'file-search',
  },
  {
    name: 'Brand',
    path: 'product-brand',
    icon: 'tag',
  },
  {
    name: 'Category',
    path: 'category',
    icon: 'profile',
  },
  {
    name: 'User',
    path: 'users',
    icon: 'user',
  },
  {
    name: 'User Inspector',
    path: 'user-inspector',
    icon: 'file-search',
  },
  {
    name: 'User Identity',
    path: 'user-identity',
    icon: 'audit',
  },
  {
    name: 'Hot Seller',
    path: 'hot-seller',
    icon: 'fire',
  },
  {
    name: 'Post',
    path: 'posts',
    icon: 'audit',
  },
  {
    name: 'Chat', // Please don't change the name - it's used for rendering badge new msg
    path: 'chat',
    icon: 'comment',
    badge: 10,
  },
  {
    name: 'Cash Out',
    path: 'transactions',
    icon: 'dollar',
  },
  {
    name: 'Top Up',
    path: 'deposits',
    icon: 'dollar',
  },
  {
    name: 'Topup Code',
    path: 'topup-code',
    icon: 'percentage',
  },
  {
    name: 'Search Banner',
    path: 'advertising',
    icon: 'fund',
  },
  {
    name: 'Stickers',
    path: 'stickers',
    icon: 'smile',
  },
  {
    name: 'Sample Message',
    path: 'sample-message',
    icon: 'audit',
  },
  {
    name: 'Order',
    path: 'manage-orders',
    icon: 'stock',
  },
  {
    name: 'Bank',
    path: 'manage-banks',
    icon: 'bank',
  },
  {
    name: 'Chat (Admin)',
    path: 'chat-admin',
    icon: 'comment',
  },
]

function formatter(data, parentPath = '/dashboard/', parentRole) {
  return data.map((item) => {
    let { path } = item
    if (!isUrl(path)) {
      path = parentPath + item.path
    }
    const result = {
      ...item,
      path,
      role: item.role || parentRole,
    }
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.role)
    }
    return result
  })
}

export const getMenuData = () => formatter(menuData)
