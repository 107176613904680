import { gql } from '@apollo/client'

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    _id
    # Profile
    username
    email
    phone
    nickname
    avatar
    gender
    newMsgCount
    notiUnreadCount
    notiLikeUnreadCount
    notiFollowUnreadCount
  }
`

export const ORDER_FRAGMENT = gql`
  fragment AddressFragment2 on Address {
    _id
    fullName
    phone
    addressNo
    ward {
      _id
      name
    }
    district {
      _id
      name
    }
    city {
      _id
      name
    }
  }
  fragment PurchasedSoldFragment on Order {
    _id
    orderCode
    createdAt
    updatedAt
    paymentUrl
    isFreeShip
    buyer {
      _id
      username
      avatar
      isVerifiedAccount
    }
    seller {
      _id
      username
      nickname
      avatar
      isVerifiedAccount
      shopPolicies {
        allowCod
        allowReturn
        allowUnbox
      }
    }
    itemsDealPrice
    items {
      _id
      productName
      description
      attachment {
        _id
        link
        type
        ... on Photo {
          thumbnail {
            link
            size
            width
            height
          }
        }
      }
      price
      originalPrice
      brand {
        _id
        name
      }
      size
      freeship
      shipFrom {
        ...AddressFragment2
      }
      myReview {
        _id
        rating
        content
        mediaUrl
        isAnonymous
        isLocked
        lockedAt
        expireAt
        isExpired
        returnBefore
        canReturn
      }
      cusReview {
        _id
        rating
        isLocked
      }
      soldOut
    }
    sellerReviewBuyer {
      _id
      rating
      content
      mediaUrl
      isLocked
      lockedAt
      expireAt
      isExpired
      returnBefore
      canReturn
    }
    subAmount
    prisCommissionPercent
    prisCommissionValue
    shippingFee
    shippingService {
      _id
      name
      image
      hotline
      trackingUrl
    }
    sellerAddress {
      ...AddressFragment2
    }
    shippingAddress {
      ...AddressFragment2
    }
    paymentMethod
    status
    statusLogs {
      updatedAt
      status
    }
    returnReason
    returnEvidence
    trackingCode
    canceledBy
  }
`

export const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on Message {
    _id
    createdAt
    updatedAt
    readAt
    type
    status
    content
    stickers
    attachment {
      link
      width
      height
    }
    attachments {
      link
      width
      height
    }
    conversation {
      _id
    }
    sender {
      _id
      username
      nickname
      avatar
    }
    senderAlias
    reactions {
      _id
      type
      count
      updatedAt
    }
    feeds {
      _id
      productName
      price
      originalPrice
      size
      user {
        _id
      }
      brand {
        name
      }
      attachment {
        _id
        link
        type
      }
    }
    review {
      _id
      buyer {
        _id
        username
      }
      seller {
        _id
        username
        nickname
      }
      order {
        ...PurchasedSoldFragment
      }
    }
    offer {
      _id
      status
      dealPrice
      buyer {
        _id
      }
      seller {
        _id
      }
      item {
        _id
        user {
          _id
        }
        productName
        price
        attachment {
          _id
          link
          type
          ... on Photo {
            height
            width
            thumbnail {
              link
              size
              width
              height
            }
          }
        }
      }
    }
    transaction {
      txCode
      status
      totalWithdrawal
      type
      amount
      fee
      createdAt
      withdrawalHeading
      withdrawalNotes
      status
      updatedAt
      logs {
        status
        time
        description
        title
      }
      user {
        nickname
        username
      }
      bankAccount {
        accountHolder
        accountNumber
        bankName
        logo
        bank {
          bankName
          otherNames
          logo
        }
      }
      momoAccount {
        accountHolder
        phone
      }
    }
    verificationRequest {
      status
    }
    order {
      ...PurchasedSoldFragment
    }
  }
  ${ORDER_FRAGMENT}
`

// export const OneToWatch = gql``;

export const BANK_FRAGMENT = gql`
  fragment BankFragment on Bank {
    _id
    logo
    bankName
    bankNameEN
    shortName
    otherNames
    isFeatured
    createdAt
    updatedAt
  }
`
