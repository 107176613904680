const isUrl = (str) => {
  const pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/

  if (!pattern.test(str)) {
    return false
  }
  return true
}

// /userinfo/2144/id => ['/userinfo','/useinfo/2144,'/userindo/2144/id']
const urlToList = (url) => {
  const urllist = url.split('/').filter((i) => i)
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`)
}

export { isUrl, urlToList }
