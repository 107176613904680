import moment from 'moment'

const ACCOUNTANT_ROLE = 'accountant'

export const MAPPING_ROUTE_NAME = {
  transaction: 'transactions',
  deposit: 'deposits',
  feed: 'posts',
  user: 'users',
  conversation: 'chat',
}

/**
 * THE OBJECT KEY MUST MATCH THE `model` string IN `src/routes.js`
 */
const MAPPING_URL = {
  transaction: '/dashboard/transactions',
  deposit: '/dashboard/deposits',
  feed: '/dashboard/posts',
  user: '/dashboard/users',
  conversation: '/dashboard/chat',
  order: '/dashboard/manage-orders',
  inspector: '/dashboard/user-inspector',
  'user-identity': '/dashboard/user-identity',
  'conversation-admin': '/dashboard/chat-admin',
  'explore-settings': '/dashboard/explore-settings',
  statistics: '/dashboard/statistics',
}

export const checkAccountant = (role) => {
  // return true
  return Object.keys(role).some((key) => role[key] === ACCOUNTANT_ROLE)
}

export const isNumber = (s = '') => {
  const pattern = /[0-9]/i
  return pattern.test(s)
}

/*
  homeSetting/A12345.* thì chỉ dc read/write trên homeSetting có id A12345
  homeSetting/*.* thì  dc read/write tất cả record
  homeSetting/*.read thì chỉ dc read tất cả record
*/

/**
 * @typedef {Object} PagePermission
 * @prop {boolean} read Has READ permission
 * @prop {boolean} write Has WRITE permission
 * @prop {string} url URL of page
 *
 * @typedef {{ [key: string]: PagePermission }} PermissionConfig
 */

/**
 * Build permission config for current user
 * @param {import('@pris').IAdminUser} user User to build permission config
 * @returns {PermissionConfig}
 */
export const permission = (user) => {
  const { policy = [], name = '' } = user?.role || {}
  console.log('In check perms', policy)

  /** @type {PermissionConfig} */
  const result = {}

  // If this user is Super Admin, skip permission check
  if (name.toLowerCase() === 'super admin') return {}

  // Loop through all role's policy of this user
  for (const item of policy) {
    const [pageName, perms] = item.split('/')

    if (pageName) {
      const [readPerm, writePerm] = perms.split('.')
      result[pageName] = {
        read: readPerm === '*',
        write: writePerm === '*',
        url: MAPPING_URL[pageName],
      }
    }
  }

  console.log('Check perms result', result)
  return result
}

export const addCommas = (nStr) => {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + '.' + '$2')
  }
  return x1 + x2
}

export const mapMsgPayload = (payload, currentUser) => {
  try {
    console.log(`This is user`, currentUser)
    const msg = {
      __typename: 'Message',
      _id: payload._id,
      content: payload.content || '',
      type: payload.type || '',
      createdAt: moment().toISOString(),
      updatedAt: moment().toISOString(),
      readAt: null,
      status: 'new',
      stickers: payload?.stickers || [],
      attachment: {
        link: payload?.type === 'image' ? payload.content : '',
        width: 0,
        height: 0,
      },
      attachments: [
        {
          link: payload?.type === 'image' ? payload.content : '',
          width: 0,
          height: 0,
        },
      ],
      conversation: {
        _id: '',
      },
      parent: payload.parent || null,
      feeds: null,
      reactions: [],
      review: null,
      offer: null,
      transaction: null,
      verificationRequest: null,
      order: null,
      sender: {
        _id: currentUser._id || '',
        nickname: currentUser.nickname || '',
        avatar:
          'https://d1gfjavjl4xkd7.cloudfront.net/images/f154b96e-8e91-45e7-9220-809262de1959.jpg?verify=true&width=160&height=160',
        // avatar: user.avatar || '',
        username: currentUser.username,
      },
      senderAlias: payload.senderAlias,
    }

    return msg
  } catch (error) {
    return {}
  }
}

export const generateAddress = (address) => {
  const {
    addressNo,
    ward: { name: ward },
    district: { name: district },
    city: { name: city },
  } = address
  return `${addressNo}/${ward}/${district}/${city}`
}

export const restrictChatOnly = window.location.href.includes('admin-chat')
