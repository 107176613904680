import styled from 'styled-components'
import { Row, Col } from 'antd'

export const RowCustom = styled(Row)`
  width: 100%;
  height: 100vh;
`
export const LargeCol = styled(Col)`
  padding: 40px;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
  }
`
export const SmallCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Title = styled.h2`
  font-size: 36px;
  font-weight: 500;
  color: #5e5873;
`
export const Text = styled.p`
  font-size: 16px;
`
