import React from 'react'
import { connect } from 'react-redux'

import { Redirect, Switch } from 'react-router-dom'
import { dashboardRoutesArray, publicRoutesArray } from 'src/routes'
import { permission } from 'src/utils/helper'
import { renderRoutes } from 'src/utils/services/router'

class Routes extends React.Component {
  filterRoute = () => {
    const isLoggedIn = this.props.token
    const admin = this.props.admin || {}
    let routes = [...(dashboardRoutesArray[0].routes || [])]
    const policy = permission(admin)
    if (isLoggedIn && Object.keys(policy).length > 0) {
      routes = routes.filter((item) => {
        // item.path.toLowerCase() === '/dashboard/transactions' && item.model.toLowerCase() === 'transaction'
        const { path, model } = item
        return policy[model] && policy[model]?.url?.toLowerCase() === path
      })
      dashboardRoutesArray[0].routes = routes
    }
    return renderRoutes(dashboardRoutesArray)
  }

  navigateHelper = () => {
    const isLoggedIn = this.props.token
    const admin = this.props.admin || {}
    const policy = permission(admin)
    if (!isLoggedIn) {
      return <Redirect to="/auth/login" />
    } else if (isLoggedIn && Object.keys(policy).length > 0) {
      const key = Object.keys(policy)[0] // get policy
      const { url } = policy[key]
      return <Redirect to={url} />
    }
    return <Redirect to="/dashboard/statistics" />
  }

  render() {
    return (
      <Switch>
        {renderRoutes(publicRoutesArray)}
        {this.filterRoute()}
        {this.navigateHelper()}
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin,
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(Routes)
