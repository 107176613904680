import { ADD_CONVERSATION, MARK_READ, SET_LIST_CONVERSATION } from './contants'

const INITIAL_STATE = {
  listConversation: [],
}

export const conversationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LIST_CONVERSATION:
      return {
        ...state,
        listConversation: action.payload,
      }
    case ADD_CONVERSATION:
      return {
        ...state,
        listConversation: [action.payload, ...state.listConversation],
      }
    case MARK_READ:
      // Get current list conversation
      const listOldConv = state.listConversation
      // Find conversation which need to be updated
      const convIndex = listOldConv.findIndex((conv) => conv._id === action.payload)
      // Set newMsg to 0
      const updateData = { ...listOldConv[convIndex], newMsg: 0 }
      // Update conversation
      listOldConv[convIndex] = updateData
      return {
        ...state,
        listConversation: [...listOldConv],
      }
    default:
      return state
  }
}
