import React, { PureComponent } from 'react'
import { Layout, Menu, Badge } from 'antd'
import { connect } from 'react-redux'
import { Icon } from '@ant-design/compatible'
import { pathToRegexp } from 'path-to-regexp'
import { Link } from 'react-router-dom'

import { urlToList } from 'src/utils/url'
import Images from 'src/utils/images'
import { permission } from 'src/utils/helper'
import styles from './styles'
import { Container, Logo } from './styled'
const { Sider } = Layout
const { SubMenu } = Menu

// Allow menu.js config icon as string or ReactNode
//   icon: 'setting',
//   icon: 'http://demo.com/icon.png',
//   icon: <Icon type="setting" />,
const getIcon = (icon) => {
  if (typeof icon === 'string' && icon.indexOf('http') === 0) {
    return <img src={icon} alt="icon" className={`${styles.icon} sider-menu-item-img`} />
  }
  if (typeof icon === 'string') {
    return <Icon style={{ fontSize: 20 }} type={icon} />
  }
  return icon
}

/**
 * Recursively flatten the data
 * [{path:string},{path:string}] => {path,path2}
 * @param  menu
 */
export const getFlatMenuKeys = (menu) =>
  menu.reduce((keys, item) => {
    keys.push(item.path)
    if (item.children) {
      return keys.concat(getFlatMenuKeys(item.children))
    }
    return keys
  }, [])

/**
 * Find all matched menu keys based on paths
 * @param  flatMenuKeys: [/abc, /abc/:id, /abc/:id/info]
 * @param  paths: [/abc, /abc/11, /abc/11/info]
 */
export const getMenuMatchKeys = (flatMenuKeys, paths) =>
  paths.reduce((matchKeys, path) => matchKeys.concat(flatMenuKeys.filter((item) => pathToRegexp(item).test(path))), [])

class SiderMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.menus = props.menuData
    this.flatMenuKeys = getFlatMenuKeys(props.menuData)
    this.state = {
      openKeys: this.getDefaultCollapsedSubMenus(props),
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({
        openKeys: this.getDefaultCollapsedSubMenus(this.props),
      })
    }
  }

  /**
   * Convert pathname to openKeys
   * /list/search/articles = > ['list','/list/search']
   * @param  props
   */
  getDefaultCollapsedSubMenus(props) {
    const {
      location: { pathname },
    } = props || this.props
    const keyList = getMenuMatchKeys(this.flatMenuKeys, urlToList(pathname))
    if (!keyList.includes('/dashboard/content-approval')) {
      keyList.unshift('/dashboard/content-approval')
    }
    return keyList
  }

  getMenuItemPath = (item) => {
    const icon = getIcon(item.icon)
    const { target, name, path, badge } = item
    const { location, badgeData = [] } = this.props
    // BadgeData has been commented at BasicLayout - we will comment it here too
    // const hasNewData = badgeData.find((data) => data.name === name)
    if (name.toLowerCase() === 'chat') {
      return (
        <Link
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
          to={path}
          target={target}
          replace={path === location.pathname}
        >
          {icon}
          <span>{name}</span>
          {this.props.newMsgCount > 0 ? (
            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
              <Badge count={this.props.newMsgCount} />
            </div>
          ) : null}
        </Link>
      )
    }
    return (
      <Link
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
        to={path}
        target={target}
        replace={path === location.pathname}
      >
        {icon}
        <span>{name}</span>
        {badge ? (
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            <Badge count={badge} />
          </div>
        ) : null}
      </Link>
    )
  }

  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = (item) => {
    if (item.children && item.children.some((child) => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children)
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu
            title={
              item.icon ? (
                <span>
                  {getIcon(item.icon)}
                  <span>{item.name}</span>
                </span>
              ) : (
                item.name
              )
            }
            key={item.path}
          >
            {childrenItems}
          </SubMenu>
        )
      }
      return null
    }
    return <Menu.Item key={item.path}>{this.getMenuItemPath(item)}</Menu.Item>
  }

  getNavMenuItems = (menusData) => {
    if (!menusData) {
      return []
    }
    let menu = [...menusData]
    const { role = {} } = this.props.currentAdmin // if doesn't logic, role will be an empty object
    if (role.name.toLowerCase() !== 'super admin') {
      const policy = permission(this.props.currentAdmin)
      const allowedRoutes = Object.keys(policy).map((k) => policy[k].url.toLowerCase())
      menu = menu.filter((item) => allowedRoutes.includes(item.path.toLowerCase()))
    }
    return menu
      .filter((item) => item.name && !item.hideInMenu)
      .map((item) => {
        // make dom
        const ItemDom = this.getSubMenuOrItem(item)
        return ItemDom
      })
      .filter((item) => item)
  }

  // Get the currently selected menu
  getSelectedMenuKeys = () => {
    const {
      location: { pathname },
    } = this.props
    return getMenuMatchKeys(this.flatMenuKeys, urlToList(pathname))
  }

  checkPermissionItem = (role, ItemDom) => {
    const { currentAdmin } = this.props
    if (Array.isArray(role) && Array.isArray(currentAdmin?.role?.policy)) {
      return role.some((r) => currentAdmin.role.policy.includes(r)) ? ItemDom : null
    }
    return ItemDom
  }

  isMainMenu = (key) => this.menus.some((item) => key && (item.key === key || item.path === key))

  handleOpenChange = (openKeys) => {
    const lastOpenKey = openKeys[openKeys.length - 1]
    const moreThanOne = openKeys.filter((openKey) => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys],
    })
  }

  handleChangeImage = (info) => {
    const { currentAdmin } = this.props
    // Get this url from response in real world.
    Images.getBase64(info.file, async (imageUri) => {
      const blob = Images.dataURItoBlob(imageUri)
      try {
        await this.uploadAvatar(currentAdmin._id, blob)
      } catch (err) {
        console.error(err)
      }
    })
  }

  uploadAvatar = async (profileId, data) =>
    new Promise((resolve, reject) => this.props.uploadAvatar({ profileId, data, resolve, reject }))
  checkMenuContent = () => { }
  render() {
    const { collapsed, onCollapse } = this.props
    const { openKeys } = this.state
    // Don't show popup menu when it is been collapsed
    const menuProps = collapsed ? {} : { openKeys }
    // if pathname can't match, use the nearest parent's key
    let selectedKeys = this.getSelectedMenuKeys()
    if (!selectedKeys.length) {
      selectedKeys = [openKeys[openKeys.length - 1]]
    }
    return (
      <Sider
        breakpoint="lg"
        className={styles.sider}
        collapsed={collapsed}
        collapsible
        onCollapse={onCollapse}
        // theme="dark"
        trigger={null}
      // width={256}
      >
        <Container>
          <Logo src={"/assets/images/pris-logo.png"} />
        </Container>
        <Menu
          {...menuProps}
          className={styles.menu}
          key="Menu"
          mode="inline"
          onOpenChange={this.handleOpenChange}
          selectedKeys={selectedKeys}
          style={{ marginTop: '16px' }}
        // theme="dark"
        >
          {this.getNavMenuItems(this.menus)}
        </Menu>
      </Sider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentAdmin: state?.auth?.admin || {},
  }
}

export default connect(mapStateToProps)(SiderMenu)
