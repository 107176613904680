import { takeLatest, put, call, all } from 'redux-saga/effects'
import { LOGIN, LOGOUT } from 'src/utils/types'
import { push } from 'connected-react-router'
import { getStore } from 'src/store'

import { fetchAccessToken } from 'src/utils/api'
import { checkAccountant, permission } from 'src/utils/helper'

function* login(action) {
  const { email, password, token, resolve, reject } = action.payload
  try {
    const response = yield call(fetchAccessToken, { username: email, password, token })
    if (response.ok) {
      const responseJson = yield response.json()
      if (!responseJson.data.user.isAdmin) throw new Error('Invalid login request')
      yield put({
        type: LOGIN.SUCCESS,
        payload: {
          token: responseJson.data.jwt,
          adminId: responseJson.data.user._id,
          admin: responseJson.data.user,
          userId: responseJson.data.user.user._id,
          user: responseJson.data.user.user,
        },
      })
      yield call(resolve)
      // console.log(responseJson.data)
      const user = responseJson.data?.user || {}
      const policy = permission(user)
      if (Object.keys(policy).length > 0) {
        // If not admin, we will navigate user to first route that they can accessed
        const key = Object.keys(policy)[0] // get policy
        const { url } = policy[key] // get the first Url in list and then navigate to it
        yield put(push(url))
      } else {
        console.log('Pushing to statistics')
        yield put(push('/dashboard/statistics'))
      }
    } else {
      throw new Error('Invalid login request')
    }
  } catch (error) {
    console.error(error)
    yield put({ type: LOGIN.FAILURE, error })
    yield call(reject)
  }
}

function* logout() {
  yield put({ type: LOGOUT.SUCCESS })
  yield call(getStore().purge)
  // yield put({ type: LOGOUT.SUCCESS })
  yield put({ type: 'RESET' })
  // we need to reload the url, so redux persist can work properly again
  window.location.href = '/'
}

// Monitoring Sagas
function* authMonitor() {
  yield all([takeLatest(LOGOUT.REQUEST, logout), takeLatest(LOGIN.REQUEST, login)])
}

export default authMonitor
