import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Button, Input, Modal } from 'antd'
import { Icon } from '@ant-design/compatible'
import { CustomButton } from '../../components/Button/styled'
// import { RowCustom } from './styled'
import { auth } from './actions'
// import imgSource from '../../assets/images/login-v2.svg'
import styles from './styles'

class LoginForm extends Component {
  async componentDidMount() {
    const { token, history } = this.props
    if (token) {
      // console.log('now still have token')
      // window.location.replace = '/dashboard/product-brand'
      history.push('/dashboard/statistics')
    } else {
      const tokenParam = new URLSearchParams(window.location.search).get('token')
      if (tokenParam) {
        await new Promise((resolve, reject) => this.props.auth({ token: tokenParam, resolve, reject }))
      }
    }
  }

  handleSubmit = async (values) => {
    try {
      await this.onSubmit(values.email, values.password)
    } catch (error) {
      Modal.error({ title: 'Login failed', content: 'Please try again' })
    }
  }

  onSubmit = (email, password) => new Promise((resolve, reject) => this.props.auth({ email, password, resolve, reject }))

  render() {
    const { loading } = this.props

    return (
      <div className={styles.main}>
        <div className={styles.login}>
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your email' }]}>
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="email"
                name="email"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]}>
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                name="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <CustomButton size="large" className={styles.submit} type="primary" htmlType="submit">
                Login
                {loading && <Icon type="loading" />}
              </CustomButton>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}

export default connect((state) => state.auth, { auth })(LoginForm)
/*
return (
      <div className={styles.main}>
        <div className={styles.login}>
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your email' }]}>
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="email"
                name="email"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]}>
              <Input.Password
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                name="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button size="large" className={styles.submit} type="primary" htmlType="submit">
                Login
                {loading && <Icon type="loading" />}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
*/
