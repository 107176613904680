import { LOGIN, UPDATE_USER, LOGOUT } from 'src/utils/types'

export function auth(params) {
  if (params) {
    return {
      type: LOGIN.REQUEST,
      payload: params,
    }
  }

  return { type: LOGIN.FAILURE, payload: 'No token' }
}

export function updateUser(params) {
  if (params) {
    return {
      type: UPDATE_USER.SUCCESS,
      payload: params,
    }
  }

  return { type: UPDATE_USER.FAILURE, payload: 'Missing user info' }
}

export function logout() {
  return {
    type: LOGOUT.REQUEST,
    payload: null,
  }
}
