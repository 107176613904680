import React, { PureComponent } from 'react'
import { Menu, Spin, Dropdown, Avatar } from 'antd'
import { Icon } from '@ant-design/compatible'
import { UserOutlined } from '@ant-design/icons'
import styles from './styles.css'

export default class GlobalHeader extends PureComponent {
  handleToggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
  }

  handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      this.props.logout()
    }
  }

  render() {
    const { currentUser = {}, collapsed } = this.props

    const menu = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.handleMenuClick}>
        <Menu.Item key="logout" data-cy="menu-logout">
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    )

    return (
      <div className={styles.header}>
        <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.handleToggle} />
        <div className={styles.right}>
          {currentUser.token ? (
            <Dropdown overlay={menu}>
              <span className={`${styles.action} ${styles.account}`} data-cy="main-menu">
                <span className={styles.name}>{currentUser?.user?.email} </span>
                <Avatar size="default" className={styles.avatar} icon={<UserOutlined />} />
              </span>
            </Dropdown>
          ) : (
            <Spin size="small" style={{ marginLeft: 8 }} />
          )}
        </div>
      </div>
    )
  }
}
