import { gql } from '@apollo/client'
import { BANK_FRAGMENT, MESSAGE_FRAGMENT, USER_FRAGMENT } from './fragments'

// =============== Topup Code ==============

export const ADMIN_CREATE_TOPUP_CODE = gql`
  mutation adminCreateTopupCode($input: CreateTopupCodeInput!){
    adminCreateTopupCode(input:$input){
      _id
      code
    }
  }
`

export const ADMIN_UPDATE_TOPUP_CODE = gql`
  mutation adminUpdateTopupCode($id:ID!,$input: UpdateTopupCodeInput!){
    adminUpdateTopupCode(id:$id,input:$input){
      _id
      code

    }
  }
`
export const ADMIN_GET_TOPUP_CODE_LIST = gql`
  query adminGetTopupCodeList($filter: FilterForAdminGetTopupCode,$pagingInput: PagingInput!){
    adminGetTopupCodeList(filter: $filter,pagingInput: $pagingInput){
        docs{
          _id
          code
          amount
          createdBy{
            _id
            username
          }
          limitOfUse
          canWithdrawal
          expireAt
          status
          createdAt
          updatedAt
          onlyForUsers{
            _id
            username
            avatar
            gender
          }
        }
        totalDocs
        totalPages
        limit
        page
      }
    }
`
// =============== Statistics ==============
export const NEW_USER_STATS = gql`
  query newUserStats($dateFilter: DateRangeFilter!){
    newUserStats(dateFilter:$dateFilter){
        date
        newUserCount
    }
  }
`

// =============== Explore Settings ==============
export const ORDER_VALUE_STATS = gql`
  query orderValueStats($dateFilter: DateRangeFilter!){
    orderValueStats(dateFilter:$dateFilter){
      date
      totalValue
      type
    }
  }
`
export const ORDER_COUNT_STATS = gql`
  query orderCountStats($dateFilter: DateRangeFilter!){
    orderCountStats(dateFilter:$dateFilter){
      date
      orderCount
    }
  }
`

export const ADMIN_UPDATE_FEED_WITH_HASHTAGS_INPUT = gql`
  mutation adminUpdateFeedWithHashtags($id: ID!,$input: AdminUpdateFeedWithHashtagsInput!) {
    adminUpdateFeedWithHashtags(id: $id,input: $input) {
      _id
    }
  }
`
export const ADMIN_CREATE_REEL = gql`
  mutation adminCreateReel($input: AdminCreateReelInput!) {
    adminCreateReel(input: $input) {
      _id
    }
  }
`
export const ADMIN_UPDATE_REEL = gql`
  mutation adminUpdateReel($id: ID!,$input: AdminUpdateReelInput!) {
    adminUpdateReel(id: $id,input: $input) {
      _id
      name
      gender
      data
      adPhoto{
        _id
      }
    }
  }
`
export const ADMIN_DELETE_REEL = gql`
  mutation adminDeleteReel($id: ID!) {
    adminDeleteReel(id: $id) {
      _id
    }
  }
`

export const ADMIN_CREATE_STORY = gql`
  mutation adminCreateStory($input: AdminCreateStoryInput!) {
    adminCreateStory(input: $input) {
      _id
    }
  }
`

export const ADMIN_UPDATE_STORY = gql`
  mutation adminUpdateStory($id: ID!,$input: AdminUpdateStoryInput!) {
    adminUpdateStory(id: $id,input: $input) {
      _id
      name
      gender
      data
      adPhoto{
        _id
      }
    }
  }
`

export const ADMIN_DELETE_STORY = gql`
  mutation adminDeleteStory($id: ID!) {
    adminDeleteStory(id: $id) {
      _id
    }
  }
`

export const ADMIN_GET_HOME_SETTINGS = gql`
  query adminGetHomeSettings($filter: FilterHomeSettingForAdmin!,$paging: PagingInput) {
    adminGetHomeSettings(filter: $filter, paging: $paging) {
      docs {
        _id
        name
        adPhoto{
          _id
          link
        }
        data
        hashtags
        kind
        icon{
          _id
        }
      }
      totalDocs
      limit
      page
      totalPages
    }
  }
`

// =============== User Inspector ==============

export const ADMIN_GET_FOLLOWER_USERS = gql`
  query adminGetFollowUsers($type: UserType!, $filter: FilterUser!, $userId: ID, $paging: PagingInput) {
    adminGetFollowUsers(type: $type, filter: $filter, userId: $userId, paging: $paging) {
      docs {
        _id
        createdAt
        username
        avatar
        isVerifiedAccount
        nickname
        phone
        email
        facebook
        instagram
        tiktok
        balance
        incentiveBalance
      }
      page
      limit
      totalPages
      totalDocs
    }
  }
`

export const ADMIN_LIST_USER_BRAND_STYLES = gql`
  query adminListUserBrandStyles($paging: PagingInput) {
    adminListUserBrandStyles(paging: $paging) {
      docs {
        _id
        name
        group
        order
      }
    }
  }
`

export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

export const ADMIN_INSPECT_RELATED_USERS = gql`
  query adminInspectRelatedUsers($userId: ID!, $filter: FilterInspectUserForAdmin) {
    adminInspectRelatedUsers(userId: $userId, filter: $filter) {
      docs {
        userInfo {
          avatar
          username
          nickname
          email
          phone
        }
        totalTopupAmount
        totalTopupCount
        totalBuyOrder
        totalSellOrder
        totalBuyer
        totalSeller
        totalDeposit
        totalWithdrawal
        topupCodes {
          _id
          code
          amount
        }
        totalBuyAmount
        totalBuyIncentiveAmount
        totalIncomeAmount
      }
      totalDocs
      limit
      page
      totalPages
    }
  }
`

export const ADMIN_GET_ORDER_LIST = gql`
  query AdminGetOrderList($username: String, $filter: FilterOrderForAdmin, $page: Int = 1, $limit: Int = 10) {
    adminGetOrderList(username: $username, filter: $filter, page: $page, limit: $limit) {
      docs {
        _id
        status
        shippingAddress {
          addressNo
          phone
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
        }
        sellerAddress {
          addressNo
          phone
          ward {
            name
          }
          district {
            name
          }
          city {
            name
          }
        }
        buyer {
          username
          phone
        }
        seller {
          username
          phone
        }
        items {
          _id
          productName
          attachment {
            thumbnailUrl
            status
            type
            link
          }
        }
        paymentMethod
        subAmount
        incentiveSubAmount
        isFreeShip
        shippingFee
        orderCode
        trackingCode
        shippingService {
          _id
          name
        }
        createdAt
      }
      totalDocs
      limit
      page
      totalPages
    }
  }
`

export const ADMIN_SEARCH_USER_BY_USERNAME = gql`
  query adminSearchUserByUsername($username: String!) {
    adminSearchUserByUsername(username: $username) {
      _id
      username
      nickname
    }
  }
`

export const ADMIN_INSPECT_USER = gql`
  query adminInspectUser($userId: ID!, $filter: FilterInspectUserForAdmin) {
    adminInspectUser(userId: $userId, filter: $filter) {
      userInfo {
        _id
        avatar
        nickname
        reviewAvgRating
        soldReviewCount
        username
        email
        phone
        facebook
        instagram
        tiktok
        balance
        incentiveBalance
        followerCount
        followingCount
        isVerifiedAccount
        verificationStatus {
          reliability
          identityCard
          email
          phone
          facebook
          instagram
          tiktok
        }
        shopPolicies {
          allowCod
          allowUnbox
          allowReturn
        }
      }
      totalTopupAmount
      totalTopupCount
      totalBuyOrder
      totalSellOrder
      totalBuyer
      totalSeller
    }
  }
`
// =============== Sample Message ==============

export const GET_ADMIN_SETTING = gql`
  query GetAdminSetting {
    getAdminSetting {
      _id
      type
      data
    }
  }
`

export const INSERT_PREPARED_MESSAGE = gql`
  mutation insertPreparedMessages($messages: [String]) {
    insertPreparedMessages(messages: $messages) {
      _id
    }
  }
`
export const REMOVE_PREPARED_MESSAGE = gql`
  mutation removePreparedMessages($messages: [String]) {
    removePreparedMessages(messages: $messages) {
      _id
    }
  }
`
// =============== End Sample Message ==============

export const ADMIN_GET_PRODUCT_BRAND_LIST = gql`
  query adminListProductBrands($filter: FilterProductBrandForAdmin, $paging: PagingInput) {
    adminListProductBrands(filter: $filter, paging: $paging) {
      docs {
        _id
        name
        style
        styles
        image
        cover
        createdAt
        gender
        status
      }
      totalDocs
    }
  }
`
// =============== Start User Identity ==============
export const GET_VERIFICATION_REQUEST_LIST = gql`
  query GetVerificationRequestList(
    $type: [DocumentType!]
    $status: [VerificationRequestStatus!]
    $page: Int = 1
    $limit: Int = 10
  ) {
    getVerificationRequestList(type: $type, status: $status, page: $page, limit: $limit) {
      docs {
        _id
        user {
          _id
          email
          username
          phone
        }
        documentType
        images
        additionalInfo {
          fullName
          gender
          dob
          idNumber
          issuedPlace
          issuedDate
          expiredDate
        }
        status
      }
      totalDocs
      limit
      page
    }
  }
`
export const APPROVE_VERIFICATION_REQUEST = gql`
  mutation ApproveVerificationRequest($requestId: ID!) {
    approveVerificationRequest(requestId: $requestId) {
      _id
    }
  }
`
export const REJECT_VERIFICATION_REQUEST = gql`
  mutation RejectVerificationRequest($requestId: ID!, $rejectReason: String!) {
    rejectVerificationRequest(requestId: $requestId, rejectReason: $rejectReason) {
      _id
    }
  }
`
// =============== End User Identity ==============
// =============== Start Home Advertising ==============

export const ADMIN_DELETE_ADVERTISING = gql`
  mutation adminDeleteAdvertising($id: ID!) {
    adminDeleteAdvertising(id: $id) {
      _id
      name
      updatedAt
      name
      kind
      categories {
        name
      }
      brands {
        name
      }
      keywords
    }
  }
`
export const ADMIN_UPDATE_ADVERTISING = gql`
  mutation adminUpdateAdvertising($id: ID!, $input: AdminUpdateAdvertisingInput!) {
    adminUpdateAdvertising(id: $id, input: $input) {
      _id
      createdAt
      updatedAt
      name
      kind
      gender
      categories {
        name
      }
      brands {
        name
      }

      keywords
      adPhoto {
        link
      }
    }
  }
`
export const ADMIN_CREATE_ADVERTISING = gql`
  mutation adminCreateAdvertising($input: AdminCreateAdvertisingInput!) {
    adminCreateAdvertising(input: $input) {
      _id
      createdAt
      updatedAt
      name
      kind
      gender
      categories {
        name
      }
      brands {
        name
      }
      adPhoto {
        link
      }
      keywords
    }
  }
`
export const ADMIN_GET_HOME_ADVERTISING = gql`
  query adminGetHomeAdvertising($gender: Gender!, $page: Int = 1, $limit: Int = 10) {
    adminGetHomeAdvertising(gender: $gender, page: $page, limit: $limit) {
      docs {
        _id
        createdAt
        updatedAt
        name
        kind
        gender
        adPhoto {
          link
          width
          height
          thumbnail {
            link
            size
          }
        }
        brandStyle
      }
      page
      totalDocs
      totalPages
    }
  }
`

// =============== Start Transaction ==============

export const ADMIN_CANCEL_TRANSACTION = gql`
  mutation adminCancelWithdrawal($id: ID!, $cancelReason: String!) {
    adminCancelWithdrawal(id: $id, cancelReason: $cancelReason) {
      _id
      type
    }
  }
`
export const ADMIN_APPROVE_TRANSACTION = gql`
  mutation adminApproveWithdrawal($id: ID!, $proof: ID!) {
    adminApproveWithdrawal(id: $id, proof: $proof) {
      _id
      type
    }
  }
`
export const ADMIN_GET_TRANSACTION_LIST = gql`
  query AdminGetTransactionList(
    $user: ID
    $timeRange: [DateTime]
    $type: [TransactionType]
    $status: [TransactionStatus]
    $page: Int
    $limit: Int
  ) {
    adminGetTransactionList(user: $user, timeRange: $timeRange, type: $type, status: $status, page: $page, limit: $limit) {
      docs {
        _id
        txCode
        createdAt
        updatedAt
        status
        amount
        totalWithdrawal
        fee
        balanceAfterTx
        type
        topupCode
        paymentSource
        order {
          _id
          orderCode
        }
        buyer {
          _id
          username
        }
        seller {
          _id
          username
        }
        user {
          _id
          username
          nickname
          avatar
          balance
        }
        bankAccount {
          bank {
            bankName
          }
          bankName # Old.Use bank.bankName instead
          accountHolder
          accountNumber
        }
        momoAccount {
          accountHolder
          phone
        }
        approvedBy {
          _id
          username
        }
        proof {
          _id
          link
        }
        cancelledBy {
          _id
          username
        }
        cancelReason
        incentiveAmount
        incentiveBalanceAfterTx
        balanceAfterTx
      }
      page
      limit
      totalDocs
      totalPages
    }
  }
`

// ============== Start Admin Chat ===============
export const MARK_READ = gql`
  mutation MarkRead($convId: ID!) {
    markRead(convId: $convId) {
      ...MessageFragment
      parent {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`
export const ADMIN_GET_CONVERSATION_BY_USERID = gql`
  query AdminGetConversationByUser($userId: ID!) {
    adminGetConversationByUser(userId: $userId) {
      _id
      createdAt
      updatedAt
      users {
        _id
        nickname
        username
        avatar
      }
      lastMsg {
        content
        createdAt
      }
      newMsg
    }
  }
`
export const MARK_READ_NEW = gql`
  mutation MarkReadNew($convId: ID!) {
    markReadNew(convId: $convId)
  }
`
export const MESSAGE_UPDATE = gql`
  subscription messageUpdated($convId: ID!) {
    messageUpdated(convId: $convId) {
      ...MessageFragment
      parent {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`
export const MESSAGE_ADDED = gql`
  subscription messageAdded($convId: ID!) {
    messageAdded(convId: $convId) {
      ...MessageFragment
      parent {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`
export const CONVERSATION_ADDED = gql`
  subscription ConversationAdded {
    conversationAdded {
      _id
      createdAt
      updatedAt
      users {
        _id
        nickname
        username
        avatar
      }
      lastMsg {
        content
        createdAt
      }
      newMsg
    }
  }
`
export const CONVERSATION_UNREAD_COUNT = gql`
  subscription ConversationUnreadCount {
    conversationUnreadCount
  }
`
export const SEND_MESSAGE = gql`
  mutation SendMessageNew(
    $convId: ID!
    $content: String!
    $type: MessageType
    $attachment: ID
    $feeds: [ID!]
    $msgId: ID
    $parentId: ID
    $senderAlias: String
  ) {
    sendMessageNew(
      convId: $convId
      content: $content
      type: $type
      attachment: $attachment
      feeds: $feeds
      msgId: $msgId
      parentId: $parentId
      senderAlias: $senderAlias
    )
  }
`
export const CREATE_CONVERSATION = gql`
  mutation createConv($userId: ID!, $content: String!, $type: String, $attachment: ID) {
    createConv(userId: $userId, content: $content, type: $type, attachment: $attachment) {
      _id
      createdAt
      updatedAt
      users {
        _id
        nickname
        username
        avatar
      }
      lastMsg {
        content
        createdAt
      }
      newMsg
    }
  }
`
export const CREATE_CONVERSATION_NEW = gql`
  mutation createConvNew($userId: ID!) {
    createConvNew(userId: $userId) {
      _id
      createdAt
      updatedAt
      users {
        _id
        nickname
        username
        avatar
      }
      lastMsg {
        content
        createdAt
      }
      newMsg
    }
  }
`
export const ADMIN_DELETE_MESSAGE = gql`
  mutation AdminDeleteMessage($msgId: ID!) {
    adminDeleteMessage(msgId: $msgId)
  }
`
export const ADMIN_GET_MESSAGE = gql`
  query AdminGetMessages($convId: ID!, $cursor: DateTime, $limit: Int) {
    adminGetMessages(convId: $convId, cursor: $cursor, limit: $limit) {
      ...MessageFragment
      parent {
        ...MessageFragment
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`
export const ALL_CONVERSATIONS = gql`
  query AllConversations($cursor: DateTime, $filter: FilterAllConversation) {
    allConversations(cursor: $cursor, filter: $filter) {
      _id
      createdAt
      updatedAt
      users {
        _id
        nickname
        username
        avatar
        lastDeviceUsed
      }
      lastMsg {
        content
        createdAt
        type
      }
      newMsg
    }
  }
`

// ============== Start Admin Brand ===============

export const ADMIN_LIST_PRODUCT_BRANDS = gql`
  query AdminListProductBrands($filter: FilterProductBrandForAdmin, $paging: PagingInput) {
    adminListProductBrands(filter: $filter, paging: $paging) {
      docs {
        _id
        name
      }
      totalDocs
      totalPages
    }
  }
`

// ============== End Admin Brand ===============

// ============== Start Admin Post ===============

export const ADMIN_GET_FEED_LIST = gql`
  query AdminGetFeedList($status: FeedStatus!, $filter: FilterFeedForAdmin, $paging: PagingInput, $sort: [SortParam]) {
    adminGetFeedList(status: $status, filter: $filter, paging: $paging, sort: $sort) {
      docs {
        _id
        description
        hiddenFromExplore
        productName
        price
        createdAt
        description
        hashTags
        category {
          name
        }
        brand {
          name
        }
        attachment {
          _id
          link
          type
          width
          height
        }
        user {
          _id
          email
          nickname
          username
          phone
          brandStyles {
            _id
            name
          }
        }
        availability
        isAuthenic
        slug
      }
      page
      totalDocs
      totalPages
    }
  }
`
export const ADMIN_UPDATE_POST = gql`
  mutation AdminUpdatePost($id: ID!, $input: AdminUpdatePostInput!) {
    adminUpdatePost(id: $id, input: $input) {
      _id
      brand {
        name
      }
      category {
        name
      }
    }
  }
`
export const ADMIN_BULK_UPDATE_STATUS_POST = gql`
  mutation AdminBulkUpdateStatusPost($ids: [ID!]!, $status: FeedStatus!, $reason: String, $outlierScreens: [String]) {
    adminBulkUpdateStatusPost(ids: $ids, status: $status, reason: $reason, outlierScreens: $outlierScreens) {
      _id
    }
  }
`
export const ADMIN_UPDATE_STATUS_POST = gql`
  mutation AdminUpdateStatusPost($id: ID!, $status: FeedStatus!, $reason: String, $outlierScreens: [String]) {
    adminUpdateStatusPost(id: $id, status: $status, reason: $reason, outlierScreens: $outlierScreens) {
      _id
      brand {
        name
      }
    }
  }
`
export const ADMIN_HIDE_POST_FROM_EXPLORE = gql`
  mutation AdminHidePostFromExplore($id: [ID!]!, $hidden: Boolean!) {
    adminHidePostFromExplore(id: $id, hidden: $hidden) {
      _id
      brand {
        name
      }
    }
  }
`
export const GET_USER_POSTS = gql`
  query GetUserPosts($userId: ID!, $category: SearchCategory, $limit: Int, $offset: Int) {
    getUserPosts(userId: $userId, category: $category, limit: $limit, offset: $offset) {
      _id
      description
      productName
      price
      createdAt
      description
      hashTags
      category {
        name
      }
      brand {
        name
      }
      attachment {
        _id
        link
        type
      }
      user {
        email
        nickname
        username
        phone
      }
      availability
      isAuthenic
    }
  }
`
export const ADMIN_GET_USER_SOLD_LIST = gql`
  query AdminGetUserSoldList($userId: ID!, $status: [OrderStatus], $limit: Int, $offset: Int) {
    adminGetUserSoldList(userId: $userId, status: $status, limit: $limit, offset: $offset) {
      _id
      createdAt
      orderCode
      momoTransId
      trackingCode
      ghnShopId
      buyer {
        _id
        username
        phone
      }
      seller {
        _id
        username
        phone
      }
      message
      returnReason
      returnEvidence
      canceledBy
      status
      items {
        _id
        productName
        category {
          _id
          image
          name
        }
        size
      }
    }
  }
`
export const ADMIN_GET_USER_PURCHASED_LIST = gql`
  query AdminGetUserPurchasedList($userId: ID!, $status: [OrderStatus], $limit: Int, $offset: Int) {
    adminGetUserPurchasedList(userId: $userId, status: $status, limit: $limit, offset: $offset) {
      _id
      createdAt
      orderCode
      momoTransId
      trackingCode
      ghnShopId
      buyer {
        _id
        username
        phone
      }
      seller {
        _id
        username
        phone
      }
      message
      returnReason
      returnEvidence
      canceledBy
      status
      items {
        _id
        productName
        category {
          _id
          image
          name
        }
        size
      }
    }
  }
`
// ============== End Admin Post ===============

// User
export const ADMIN_SEARCH_USER_BY_ID = gql`
  query AdminSearchUserById($id: ID!) {
    adminSearchUserById(id: $id) {
      _id
      username
      email
      phone
      nickname
      avatar
      isVerifiedAccount
    }
  }
`
export const ADMIN_UPDATE_USER = gql`
  mutation adminUpdateUser($id: [ID!]!, $userInput: UpdateUserInput!) {
    adminUpdateUser(id: $id, userInput: $userInput) {
      username
      nickname
      _id
    }
  }
`
export const ADMIN_CREATE_USER = gql`
  mutation AdminCreateUser($userInput: UpdateUserInput!) {
    adminCreateUser(userInput: $userInput) {
      username
      nickname
    }
  }
`
export const ADMIN_GET_USER_LIST = gql`
  query AdminGetUserList($filter: FilterUserForAdmin, $paging: PagingInput, $sort: [SortParam]) {
    adminGetUserList(filter: $filter, paging: $paging, sort: $sort) {
      docs {
        _id
        username
        email
        phone
        nickname
        avatar
        createdAt
        isVerifiedAccount

        gender
        birthday
        balance
        incentiveBalance
        soldReviewCount
        reviewAvgRating

        facebook
        instagram
        tiktok
        verificationStatus {
          reliability
          identityCard
          email
          phone
          facebook
          instagram
          tiktok
        }

        brandStyles {
          _id
          name
        }
      }
      totalDocs
      totalPages
    }
  }
`
export const ADMIN_SEARCH_USER_BY_LINK = gql`
  query AdminSearchUserByLink($postLink: String!) {
    adminSearchUserByPostLink(postLink: $postLink) {
      _id
      username
      email
      phone
      nickname
      avatar
    }
  }
`

// Categories
export const ADMIN_DELETE_CATEGORY = gql`
  mutation AdminDeleteCategory($id: ID!) {
    adminDeleteCategory(id: $id) {
      _id
      name
    }
  }
`
export const ADMIN_SEARCH_CATEGORY = gql`
  query AdminSearchCategory($keyword: String!, $page: Int, $perPage: Int) {
    searchCategory(keyword: $keyword, page: $page, perPage: $perPage) {
      docs {
        name
        _id
        code
        description
        image
        group
        parent {
          _id
          name
        }
      }
      totalDocs
      totalPages
    }
  }
`
export const ADMIN_GET_CATEGORY = gql`
  query AdminGetCategory($id: ID!) {
    adminGetCategory(id: $id) {
      _id
      name
      description
      image
      parent {
        _id
        name
      }
    }
  }
`
export const ADMIN_UPDATE_CATEGORY = gql`
  mutation AdminUpdateCategory($id: ID!, $adminUpdateCategoryInput: AdminUpdateCategoryInput!) {
    adminUpdateCategory(id: $id, adminUpdateCategoryInput: $adminUpdateCategoryInput) {
      _id
      name
    }
  }
`
export const ADMIN_CREATE_CATEGORY = gql`
  mutation AdminCreateCategory($adminCreateCategoryInput: AdminCreateCategoryInput!) {
    adminCreateCategory(adminCreateCategoryInput: $adminCreateCategoryInput) {
      _id
      name
    }
  }
`
export const ADMIN_LIST_CATEGORIES = gql`
  query AdminListCategories($page: Int!, $perPage: Int!) {
    adminListCategories(page: $page, perPage: $perPage) {
      docs {
        name
        _id
        code
        description
        image
        parent {
          _id
          name
        }
      }
      totalDocs
      totalPages
    }
  }
`
export const ADMIN_LIST_PARENT_CATEGORIES = gql`
  query ListCategories {
    listCategories {
      _id
      name
      group
      parent {
        _id
        name
      }
    }
  }
`
// Product Brand
export const ADMIN_GET_PRODUCT_BRAND = gql`
  query AdminGetProductBrand($id: ID!) {
    adminGetProductBrand(id: $id) {
      name
      style
      styles
      shortDescription
      image
      cover
      gender
      facebook
      instagram
      tiktok
    }
  }
`
export const ADMIN_UPDATE_PRODUCT_BRAND = gql`
  mutation AdminUpdateProductBrand($id: ID!, $adminUpdateProductBrandInput: AdminUpdateProductBrandInput!) {
    adminUpdateProductBrand(id: $id, adminUpdateProductBrandInput: $adminUpdateProductBrandInput) {
      _id
      name
      facebook
      instagram
      tiktok
    }
  }
`
export const ADMIN_CREATE_PRODUCT_BRAND = gql`
  mutation AdminCreateProductBrand($adminCreateProductBrandInput: AdminCreateProductBrandInput!) {
    adminCreateProductBrand(adminCreateProductBrandInput: $adminCreateProductBrandInput) {
      _id
      name
      facebook
      instagram
      tiktok
    }
  }
`
// One To Watch
export const ADMIN_GET_OTW_LIST = gql`
  query AdminGetOnesToWatchList($page: Int = 1, $limit: Int = 10) {
    adminGetOnesToWatchList(page: $page, limit: $limit) {
      docs {
        _id
        name
        cover
        createdAt
        brand {
          _id
          name
          style
        }
        users {
          _id
          username
          nickname
          avatar
          isVerifiedAccount
        }
      }
      totalDocs
      totalPages
    }
  }
`

export const GET_OTW = gql`
  query GetOneToWatch($id: ID!) {
    onesToWatch(id: $id) {
      _id
      name
      cover
      users {
        _id
        username
        nickname
        avatar
        isVerifiedAccount
      }
      brand {
        _id
        name
        style
      }
      createdAt
    }
  }
`

export const CREATE_OTW = gql`
  mutation CreateOnesToWatch($onesToWatchInput: OnesToWatchInput!) {
    createOnesToWatch(onesToWatchInput: $onesToWatchInput) {
      _id
      name
      cover
      createdAt
      brand {
        _id
        name
        style
      }
      users {
        _id
        username
        nickname
        avatar
        isVerifiedAccount
      }
    }
  }
`
export const EDIT_OTW = gql`
  mutation EditOnesToWatch($id: ID!, $onesToWatchInput: OnesToWatchInput!) {
    editOnesToWatch(id: $id, onesToWatchInput: $onesToWatchInput) {
      _id
      name
      cover
      createdAt
      users {
        _id
        username
        nickname
        avatar
        isVerifiedAccount
      }
    }
  }
`

export const DELETE_OTW = gql`
  mutation DeleteOneToWatch($id: ID!) {
    deleteOnesToWatch(id: $id) {
      _id
      name
      cover
      createdAt
      users {
        _id
        username
        nickname
        avatar
        isVerifiedAccount
      }
    }
  }
`

export const GET_BANK_LIST = gql`
  query GetBankList {
    getBankList {
      ...BankFragment
    }
  }
  ${BANK_FRAGMENT}
`
export const ADMIN_UPDATE_BANK = gql`
  mutation AdminUpdateBank($id: ID!, $input: UpdateBankInput!) {
    adminUpdateBank(id: $id, input: $input) {
      ...BankFragment
    }
  }
  ${BANK_FRAGMENT}
`

/**
 *  --- Sticker ---
 */
export const GET_STICKER_COLLECTION = gql`
  query GetStickerCollection {
    getStickerCollection {
      items {
        stickerSet {
          _id
          name
          avatar
          stickers {
            link
            order
          }
          isFeatured
          isEnabled
        }
        order
      }
    }
    }
    `
export const GET_STICKER_SET_LIST = gql`
  query getStickerSetList($keyword: String!, $page: Int, $perPage: Int){
  getStickerSetList(keyword: $keyword, page: $page, perPage: $perPage) {
      docs {
      _id
      avatar
      name
        stickers {
        link
      }
    }
    totalDocs
    totalPages
  }
}
`

export const CREATE_STICKER_SET = gql`
  mutation createStickerSet($stickerSetInput: StickerSetInput!){
  createStickerSet(stickerSetInput: $stickerSetInput){
    _id
  }
}
`
export const ADD_STICKER_SET = gql`
  mutation addStickerSet($id: ID!){
  addStickerSet(id: $id){
    _id
  }
}
`
export const UPDATE_STICKER_SET = gql`
  mutation updateStickerSet($id: ID!, $stickerSetInput: StickerSetInput!){
  updateStickerSet(id: $id, stickerSetInput: $stickerSetInput){
    _id
  }
}
`
export const DELETE_STICKER_SET = gql`
  mutation deleteStickerSet($id: ID!){
  deleteStickerSet(id: $id){
    _id
  }
}
`
export const ADD_STICKER_TO_SET = gql`
  mutation addStickerToSet($id: ID!, $sticker: String!){
  addStickerToSet(id: $id, sticker: $sticker){
    _id
  }
}
`
export const REMOVE_STICKER_FROM_SET = gql`
  mutation removeStickerFromSet($id: ID!, $sticker: String!){
  removeStickerFromSet(id: $id, sticker: $sticker){
    _id
  }
}
`

export const CREATE_SUPER_ADMIN_TOKEN = gql`
  mutation {
  createSuperAdminToken
}
`
