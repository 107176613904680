import styled from 'styled-components'
import { Button } from 'antd'
import { colors } from '../../common/colors'

const primaryButton = () => `
border-radius: 10px;
background-color: ${colors.primary};
border: none;
outline: none;
transition: all 0.2s;
text-align: center;
color: #fff;
&:hover {
  background-color: ${colors.primary};
  transform: translateY(-2px);
  box-shadow: 0 8px 25px -8px #7367f0;
}
&:active {
  transform: translateY(-1px);
  box-shadow: 0 8px 25px -8px #7367f0;

}
&:focus {
  border: none;
  outline: none;
  background-color: ${colors.primary};
  color: #fff;
}
`
const outlineButton = (isActive) => {
  console.log(`im active in styled: ${isActive}`)
  return  `
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid ${colors.primary};
  outline: none;
  transition: all 0.2s;
  ${isActive ? `background-color: ${colors.primary}; color: #fff;` : ''};
  &:hover {
    background-color: ${colors.primary};
    transform: translateY(-2px);
    color: #fff;
    box-shadow: 0 8px 25px -8px #7367f0;
    outline: none;
    border: 1px solid ${colors.primary};
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 25px -8px #7367f0;
  
  }
  &:focus {
    border: 1px solid ${colors.primary};
    outline: none;
    background-color: #fff;
    color: #333;
  }
  `
}
export const CustomButton = styled(Button)`
  height: 30px;
  padding: 5px 20px;
  line-height: 1;
  cursor: pointer;
  ${(props) => (props.type === 'primary' ? primaryButton() : outlineButton(props.isActive))}
`
