import React from 'react'
import { Switch } from 'react-router-dom'
import { renderRoutes } from 'src/utils/services/router'
import { RowCustom, LargeCol, Title, SmallCol, Text } from './styled'
import GlobalFooter from '../components/GlobalFooter'
// import sourceImg from '/assets/images/pris-logo.png'
class UserLayout extends React.PureComponent {
  render() {
    const { routes } = this.props.route
    return (
      <RowCustom>
        <LargeCol span={16}>
          <img src={'/assets/images/login-v2.svg'} alt="logo" />
        </LargeCol>
        <SmallCol span={8}>
          <Title>Welcome back 👋</Title>
          <Text>Please sign-in to your account</Text>
          <div>
            <Switch>{renderRoutes(routes)}</Switch>
          </div>
          <GlobalFooter />
        </SmallCol>
      </RowCustom>
    )
  }
}

export default UserLayout
/* ======= Old Code =========
<div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to="/auth/login">
                <img src="/assets/images/pris-logo.png" alt="logo" style={{ width: '8em' }} />
              </Link>
            </div>
            <div className={styles.desc} />
          </div>
          <Switch>{renderRoutes(routes)}</Switch>
        </div>
        <GlobalFooter />
      </div>
*/
