import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from 'src/containers/Auth/reducer'
import rMsgReducer from 'src/store/reply-message/rMsgReducer'
import { conversationReducer } from 'src/store/chat/conversation/reducers'
// import messageReducer from 'src/store/chat/message/reducers'

const rootReducer = {
  auth,
  rMsg: rMsgReducer,
  // message: messageReducer,
  conversation: conversationReducer,
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  })
