import React from 'react'
import { Provider } from 'react-redux'
import momentTimezone from 'moment-timezone'
// import NotFound from 'src/containers/Exception/404'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from 'src/store'

import { ConnectedRouter } from 'connected-react-router'
import { ApolloProvider } from '@apollo/client'
import history from 'src/utils/history'
import client from 'src/store/apolloClient'

// Stylesheets
import './styles.css'
// import '../../style.css'

// import { checkAccountant, permission } from 'src/utils/helper'
import AppRoutes from './app-routes'
import ScrollToTop from './ScrollToTop'

momentTimezone.tz.setDefault('Asia/Ho_Chi_Minh')

const { store, persistor } = configureStore()

const Routes = () => {}

class App extends React.PureComponent {
  state = { ready: false }

  render() {
    return (
      <Provider store={store}>
        <PersistGate onBeforeLift={() => this.setState({ ready: true })} loading={<div>Loading...</div>} persistor={persistor}>
          <ApolloProvider client={client}>
            <ConnectedRouter history={history}>
              {this.state.ready && (
                <ScrollToTop>
                  <AppRoutes />
                </ScrollToTop>
              )}
            </ConnectedRouter>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
