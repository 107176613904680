import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getStore } from 'src/store'
import L from 'react-loadable'

import AuthLayout from './layouts/AuthLayout'
import LoginForm from './containers/Auth/Login'
import LoadingScreen from './components/LoadingScreen'
import BasicLayout from './layouts/BasicLayout'
import { restrictChatOnly } from './utils/helper'
// import ProductBrand from './routes/ProductBrand'
const Loadable = (opts) =>
  L({
    loading: LoadingScreen,
    ...opts,
  })

const AsyncProductBrandPage = Loadable({
  loader: () => import('./routes/ProductBrand'),
})
const AsyncCategory = Loadable({
  loader: () => import('./routes/Category'),
})
const AsyncUser = Loadable({
  loader: () => import('./routes/User'),
})
const AsyncPost = Loadable({
  loader: () => import('./routes/Post'),
})
const AsyncChat = Loadable({
  loader: () => import('./routes/Chat'),
})
const AsyncChatAdmin = Loadable({
  loader: () => import('./routes/ChatAdmin'),
})
const AsyncTransaction = Loadable({
  loader: () => import('./routes/Transaction'),
})
const AsyncDeposit = Loadable({
  loader: () => import('./routes/Deposit'),
})
const AsyncHomeAdvertising = Loadable({
  loader: () => import('./routes/HomeAdvertising'),
})
const AsyncUserIdentity = Loadable({
  loader: () => import('./routes/UserIdentity'),
})
const AsyncUserInspector = Loadable({
  loader: () => import('./routes/UserInspector'),
})
const AsyncStickers = Loadable({
  loader: () => import('./routes/Stickers'),
})
const AsyncSampleMessage = Loadable({
  loader: () => import('./routes/SampleMessage'),
})
const AsyncOrder = Loadable({
  loader: () => import('./routes/Order'),
})
const AsyncBank = Loadable({
  loader: () => import('./routes/Bank'),
})
const AsyncHotSeller = Loadable({
  loader: () => import('./routes/HotSeller'),
})
const AsyncExploreSettings = Loadable({
  loader: () => import('./routes/ExploreSettings'),
})
const AsyncStatistics = Loadable({
  loader: () => import('./routes/Statistics'),
})
const AsyncTopupCode = Loadable({
  loader: () => import('./routes/TopupCode'),
})
const createAuthorizedRoute = (Component, props) => {
  return getStore().getState().auth.token ? <Component {...props} /> : <Redirect to="/auth/login" />
}
const PublicRoutes = (
  <Route path="/auth" component={AuthLayout}>
    <Route exact path="/auth/login" component={LoginForm} />
  </Route>
)
const DashboardRoutes = (
  <Route path="/dashboard" component={(props) => createAuthorizedRoute(BasicLayout, props)}>
    <Route exact path="/dashboard" component={() => <Redirect to="/dashboard/statistics" />} />
    <Route model="product-brand" path="/dashboard/product-brand" component={AsyncProductBrandPage} />
    <Route model="category" path="/dashboard/category" component={AsyncCategory} />
    <Route model="user" path="/dashboard/users" component={AsyncUser} />
    <Route model="hot-seller" path="/dashboard/hot-seller" component={AsyncHotSeller} />
    <Route model="feed" path="/dashboard/posts" component={AsyncPost} />
    <Route model="conversation" path="/dashboard/chat" component={AsyncChat} />
    <Route model="conversation-admin" path="/dashboard/chat-admin" component={AsyncChatAdmin} />
    <Route model="transaction" path="/dashboard/transactions" component={AsyncTransaction} />
    <Route model="deposit" path="/dashboard/deposits" component={AsyncDeposit} />
    <Route model="home-advertising" path="/dashboard/advertising" component={AsyncHomeAdvertising} />
    <Route model="user-identity" path="/dashboard/user-identity" component={AsyncUserIdentity} />
    <Route model="stickers" path="/dashboard/stickers" component={AsyncStickers} />
    <Route model="inspector" path="/dashboard/user-inspector" component={AsyncUserInspector} />
    <Route model="sample-message" path="/dashboard/sample-message" component={AsyncSampleMessage} />
    <Route model="order" path="/dashboard/manage-orders" component={AsyncOrder} />
    <Route model="bank" path="/dashboard/manage-banks" component={AsyncBank} />
    <Route model="explore-settings" path="/dashboard/explore-settings" component={AsyncExploreSettings} />
    <Route model="statistics" path="/dashboard/statistics" component={AsyncStatistics} />
    <Route model="topup-code" path="/dashboard/topup-code" component={AsyncTopupCode} />
  </Route>
)
const ChatOnlyRoutes = (
  <Route path="/dashboard" component={(props) => createAuthorizedRoute(BasicLayout, props)}>
    <Route exact path="/dashboard/statistics" component={() => <Redirect to="/dashboard/chat" />} />
    <Route model="conversation" path="/dashboard/chat" component={AsyncChat} />
  </Route>
)

const getChildRoutes = (childRoutes) =>
  React.Children.map(childRoutes, ({ props: { exact, path, component, children, model } }) => ({
    exact,
    path,
    component,
    model,
    routes: children ? getChildRoutes(children) : children,
  }))

const createRouteArray = (routes) => [
  {
    exact: routes.props.exact,
    path: routes.props.path,
    component: routes.props.component,
    model: routes.props.model,
    routes: getChildRoutes(routes.props.children),
  },
]
const publicRoutesArray = createRouteArray(PublicRoutes)
const dashboardRoutesArray = createRouteArray(restrictChatOnly ? ChatOnlyRoutes : DashboardRoutes)

export { publicRoutesArray, dashboardRoutesArray }
