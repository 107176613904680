import { LOGIN, LOGOUT, UPDATE_USER } from 'src/utils/types'

const defaultUserState = {
  loading: false,
  token: null,
  admin: null, // ADMIN USER account
  adminId: '', // Id of ADMIN USER account
  user: null, // NORMAL USER account that be mapped to this ADMIN USER
  userId: '', // Id of NORMAL USER account that be mapped to this ADMIN USER
  error: '',
}

export default function auth(state = defaultUserState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case LOGIN.SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case LOGOUT.SUCCESS:
      return defaultUserState
    case LOGOUT.FAILURE:
      return defaultUserState
    case LOGIN.FAILURE:
      return { ...state, error: action.error, loading: false }
    case UPDATE_USER.SUCCESS:
      /**
       * Hiện tại backend vẫn đang trả về AdminUser cho prop `user` khi login (API login admin)
       * không phân biệt tài khoản Admin (AdminUser) và "tài khoản User được map với Admin" (User)
       * nên dưới dashboard lưu cả `admin` và `user` đều là AdminUser hết
       * 
       * Nhưng sau này, khi tính năng Chat phát triển, hỗ trợ switch giữa tài khoản Admin và tài khoản User
       * thì phải tách biệt hai props này. Lúc đó cần backend trả về prop `admin` là AdminUser và prop `user` là User
       * Như vậy dưới dashboard mới có thể lưu tách biệt 2 props này trong state và sử dụng đúng prop khi cần.
       */
      return {
        ...state,
        admin: { ...state.admin, ...action.payload },
        user: { ...state.user, ...action.payload },
      }
    default:
      return state
  }
}
