import { REMOVE_REPLY_MESSAGE, SET_REPLY_MESSAGE } from './rConstant'

const INIT_STATE = {
  msg: null,
}

const rReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_REPLY_MESSAGE:
      return { msg: action.payload }
    case REMOVE_REPLY_MESSAGE:
      return { msg: null }
    default:
      return { ...state }
  }
}
export default rReducer
